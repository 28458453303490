/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 11:41:46
 * @LastEditTime: 2022-02-08 09:35:43
 * @LastEditors: Please set LastEditors
 * @Description: 卡券相关接口
 * @FilePath: \SuperMall\src\common\api\coupon.js
 */
import domain from './domain';
import { get, post } from './axios';
import Qs from 'qs';

const wechatLoginConfig =
{
	appid: 'wxff7a18f70da090cd',
	redirect_uri: `${location.protocol}//test.zechaoxinxi.com/baseService/public/getIndex/toUrl?type=${location.origin + location.pathname}`,
	response_type: 'code',
	scope: 'snsapi_base'
};

const wechatLoginUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';

const getUrlKey = (name) =>
{
	let r = window.location.search.substr(1).match(new RegExp('(^|&)' + name + '=([^&]*)(&|$)'));

	return r != null ? unescape(r[ 2 ]) : null;
}

const getWechatCode = () =>
{
	const code = getUrlKey('code');

	if (code) return code;
	
	//console.log(wechatLoginUrl + '?' + Qs.stringify(wechatLoginConfig) + '#wechat_redirect');
	window.location.href = wechatLoginUrl + '?' + Qs.stringify(wechatLoginConfig) + '#wechat_redirect';
}

const getWechatUser = () =>
{
	if (localStorage.getItem('WECHAT_SAAS_USER'))
	{
		return JSON.parse(localStorage.getItem('WECHAT_SAAS_USER'));
	}

	return {};
}

const setWechatUser = (data) =>
{
	localStorage.setItem('WECHAT_SAAS_USER', JSON.stringify(data));
}

const wechat =
{
	jssdk(data, bag)
	{
		return post(`${domain.mobile}/activity/getSign`, data, ['url'], null, bag);
	},
	login(data, bag)
	{
		return get(`${domain.mobile}/activity/authorizedLogin/{code}`, data, [ 'code' ], null, bag);
	},
	user()
	{
		return new Promise((resolve, reject) =>
		{
			let user = getWechatUser();

			if (!user || !user.openId)
			{
				let code = getWechatCode();

				if (code)
				{
					wechat.login({ code: code })
						.then(response =>
						{
							response && setWechatUser(response);
							resolve(response);
						})
						.catch(error =>
						{
							//setTimeout(() => window.location.replace(window.location.origin), 10000);
						});
				}
			}
			else
			{
				resolve(user);
			}
		});
	}
}

export default wechat;