let CryptoJS = require('./aes.js'); //引用AES源码js
let key = CryptoJS.enc.Utf8.parse("zynz201908101231"); //十六位十六进制数作为秘钥  （后端提供，或者说明）
let iv = CryptoJS.enc.Utf8.parse("zynz20190810zynz"); //十六位十六进制数作为秘钥偏移量 （后端提供，或者说明）

export const decode = word =>
{
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

    return decryptedStr.toString();
};

export const encode = word =>
{
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    // return encrypted.ciphertext.toString().toUpperCase();  //  base64
    return encrypted.toString()
};